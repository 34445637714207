export const messages = {
  sv: {
    b2c: {
      headline: "Privat",
      "mitt-telia": {
        headline: "Mitt Telia",
        subscriptions: "Abonnemang",
        invoices: "Fakturor",
        errands: "Inkorg",
        life: "Mina förmåner",
        "my-account": "Mitt konto",
        orders: "Beställningar",
      },
      mobile: {
        headline: "Mobilt",
        phones: "Mobiltelefoner",
        subscriptions: "Mobilabonnemang",
        "cash-card": "Kontantkort",
        tablets: "Surfplattor",
        "smart-watches": "Smartklockor",
        services: "Tjänster",
        accessories: "Tillbehör",
        brands: "Varumärken",
      },
      broadband: {
        headline: "Bredband",
        fiber: "Fiber",
        "mobile-broadband": "Mobilt bredband",
        services: "Tjänster",
        routers: "Routrar",
      },
      tv: {
        headline: "Tv och streaming",
        packages: "Alla paket",
        "digital-tv": "Digital-tv",
        sports: "Sport",
        films: "Film & Serier",
        accessories: "Tillbehör",
      },
      support: {
        headline: "Support",
        mobile: "Mobiltelefoni",
        broadband: "Bredband",
        "fixed-telephony": "Fast telefoni",
        "tv-streaming": "TV och streaming",
        "apps-services": "Appar och tjänster",
      },
      contact: {
        headline: "Erbjudanden",
      },
    },

    b2b: {
      headline: "Företag",
      "my-business": {
        headline: "MyBusiness",
        "back-to-mybusiness": "Tillbaka till MyBusiness",
        "mybusiness-read-more": "Läs mer om MyBusiness",
      },
      telephony: {
        headline: "Telefoni",
        "company-phones": "Företagstelefoner",
        subscriptions: "Företagsabonnemang",
        switches: "Företagsväxlar",
        "call-center": "Kontaktcenter",
      },
      connectivity: {
        headline: "Uppkoppling",
        broadband: "Bredband",
        network: "Nätverk",
        iot: "Internet of things",
        insights: "Dataanalys",
      },
      productivity: {
        headline: "IT & produktivitet",
        productivity: "Produktivitet",
        "it-services": "IT-tjänster",
        cybersecurity: "Cybersäkerhet",
        hosting: "Hosting",
        hardware: "Hårdvara",
      },
      more: {
        headline: "Mer från Telia",
        knowledge: "Kunskap och kundcase",
        events: "Event",
        offers: "Erbjudanden",
        sustainability: "Hållbarhet",
        myBusiness: "MyBusiness",
        teliaAtWork: "Telia at Work",
      },
      support: {
        headline: "Support",
        guider: "Guider",
        drift: "Driftinformation",
        "kom-igang": "Kom igång",
        contact: "Kontakt",
      },
      "products-services": {
        headline: "Produkter & Tjänster",
      },
      "telia-at-work": {
        headline: "Telia at Work",
        home: "Hem",
        support: "Support",
      },
      about: {
        "privacy-policy": "Integritetspolicy",
      },
    },
  },

  en: {
    b2b: {
      "telia-at-work": {
        headline: "Telia at Work",
        home: "Home",
        support: "Support",
      },
      about: {
        "privacy-policy": "Privacy Policy",
      },
    },
  },
};
